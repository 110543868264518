import React , { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import $ from 'jquery';
import { FaMapMarkerAlt } from 'react-icons/fa'
import BackgroundImage from 'gatsby-background-image'
import Form from "../components/form"




function changeActive(e){
    e.preventDefault();
    $(".form").toggleClass("expanded");
    $('body').toggleClass('formExpanded');
  }

  function getUrlVars(){
    var vars = [], hash;
    if(typeof window !== 'undefined'){
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
    }
    return vars;
  }
  var city = getUrlVars()["city"];

  if (city !== undefined){
    let cityDash = city;
    cityDash = cityDash.replace(/-/g, ' ');

      var cityplace = " in " + cityDash;
      var citytitle = cityDash+"'s";

  }

export const query = graphql`
query thankyouQuery {
    sanityPages(slug: {current: {eq: "home"}}) {
        pagetitle
        heroheader
        _rawHerocopy
        slug {
            current
        }
        usp1{
          uspTitle
          uspText
          icon
        }
        usp2{
            uspTitle
            uspText
            icon
        }
        usp3{
            uspTitle
            uspText
            icon
        }
        homeservicesIcon1{
          homeservicesTitle
          homeservicesText
          homeservicesLink
          homeservicesIcon{
              asset {
                  fluid(maxWidth: 1920) {
                      ...GatsbySanityImageFluid
                  }
              }
          }
        }
        homeservicesIcon2{
          homeservicesTitle
          homeservicesText
          homeservicesLink
          homeservicesIcon{
              asset {
                  fluid(maxWidth: 1920) {
                      ...GatsbySanityImageFluid
                  }
              }
          }
        }
        homeservicesIcon3{
          homeservicesTitle
          homeservicesText
          homeservicesLink
          homeservicesIcon{
              asset {
                  fluid(maxWidth: 1920) {
                      ...GatsbySanityImageFluid
                  }
              }
          }
        }
        homeservicesIcon4{
          homeservicesTitle
          homeservicesText
          homeservicesLink
          homeservicesIcon{
              asset {
                  fluid(maxWidth: 1920) {
                      ...GatsbySanityImageFluid
                  }
              }
          }
        }
        _rawFirstcopy
        _rawServices
        coupon {
            title
            type
        }
        heroimage {
            asset {
                fluid(maxWidth: 1920) {
                    ...GatsbySanityImageFluid
                }
            }
        }
        serviceimage {
            asset {
                fluid(maxWidth: 1920) {
                  ...GatsbySanityImageFluid
                }
            }
        }
    }
    sanityCompanyInfo {
      companyname
      companyTagline     
      homepageservicebackground{
          asset {
              fluid{
                ...GatsbySanityImageFluid
                src
              }
            }
      }
      thankyouimg{
          asset {
              fluid{
                ...GatsbySanityImageFluid
                src
              }
            }
      }
      primarycolor{
        hex
        rgb{
            a
            r
            g
            b
        }
      }
      secondarycolor{
          hex
          rgb{
              a
              r
              g
              b
          }
      }
      accentcolor{
          hex
          rgb{
              a
              r
              g
              b
          }
      }
      tertiarycolor{
        hex
        rgb{
            a
            r
            g
            b
        }
      }
      tertiaryaltcolor{
        hex
        rgb{
            a
            r
            g
            b
        }
      }
    }
    allSanitySocial{
      edges{
        node{
          socialproofTitle
          socialproofNumber
          }
        }
      }
}
`

export default ({ data }) => (
    <Layout>
    <Helmet>
    <title>{data.sanityCompanyInfo.companyname} | {data.sanityPages.pagetitle}</title>   
    </Helmet>

    <div className="row heroRow">
      <div className="leftSection">
        <div className="leftSectionText">
          <p className="tagline_marker" style={{color: data.sanityCompanyInfo.tertiarycolor.hex, textTransform: 'uppercase'}}><FaMapMarkerAlt/> {data.sanityCompanyInfo.companyTagline} {cityplace}</p>
          <span className="heroBlockTitle"><h2>Thank You</h2></span>
            <p>Your form submission has been submitted successfully! We appreciate your interest in our services and we’ll get back to you as soon as possible.</p>
            <p>Our team is committed to providing high-quality home services that meet your needs and exceed your expectations. If you have any questions in the meantime, don’t hesitate to contact us directly. We look forward to serving you!</p>
        </div>
      </div>
      <div className="rightSection">
        <BackgroundImage 
        style={{height: "100%", backgroundSize: "cover"}}
        fluid={data.sanityCompanyInfo.thankyouimg.asset.fluid}>
        </BackgroundImage>
      </div>
    </div>
    
    

    
  </Layout>
)